<section dir="ltr">
  <button [disabled]="isDisable" (click)="onStart()">Start Translate</button>
  <div class="actions">
    <span *ngFor="let item of (actions | async).actions">
      <i class="pi pi-check"></i> {{ item }}
    </span>

    <strong>{{ (actions | async).loading }}</strong>
    <strong *ngIf="(actions | async)?.done" class="done"> <i class="pi pi-check"></i> <i class="pi pi-check"></i>{{ (actions | async)?.done }} <i class="pi pi-check"></i> <i class="pi pi-check"></i></strong>
  </div>
</section>
