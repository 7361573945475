import { Component, OnDestroy, OnInit } from '@angular/core';
import { FooterNavs } from '../nav/nav.const';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, OnDestroy {
  navs = FooterNavs;
  isDarkBg: boolean = false;
  private routerSubscription?: Subscription;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs'));
    // Subscribe to the router events to detect changes
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL matches the profile page
        this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs'));
      }
    });
  }
  goToComponent() {
    window.location.href = window.location.origin+"#services"
  }
  ngOnDestroy(): void {
    // Unsubscribe from the router events to avoid memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
