import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { map, mergeMap, Observable, ReplaySubject, switchMap } from 'rxjs';
import { environment } from '@environment/enviroment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageKeys } from '@app/core/configs/storage.config';
import { CustomLanguageService } from '../language-service';

export function createTranslateLoader(
  languageService: CustomLanguageService,
  httpClient: HttpClient,
) {
  if (environment.production) {
    languageService.translationMode.next('external');
    return new CustomTranslateLoader();
  } else {
    languageService.translationMode.next('internal');
    return new TranslateHttpLoader(
      httpClient,
      '../../../../assets/i18n/',
      '.json',
    );
  }
}

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return `**MISSING KEY**`;
  }
}

export class CustomTranslateLoader implements TranslateLoader {
  languageServices = inject(CustomLanguageService);
  constructor() {}
  getTranslation(lang: string): Observable<any> {
    let getLang$ = new ReplaySubject<any>(1);
    this.languageServices.languageData.subscribe((x) => {
      getLang$.next(x);
    });
    return getLang$
      .asObservable()
      .pipe(mergeMap((x) => this.languageServices.languageData))
      .pipe(map((x) => x[lang]));
  }
}
