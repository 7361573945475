import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BaseComponent } from '@app/shared/base-component';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [RouterOutlet, NgIf],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent extends BaseComponent {
  protected override translatedContent(): void {
  }
}
