import { Routes } from '@angular/router';
import { setLayout } from './core/layout/services/layout.resolver';
import { PageLayout } from './core/layout/configs/layout.enum';
import { authGaurd } from './core/auth/guards/auth.guard';
import { notAuthGaurd } from './core/auth/guards/not-auth.gaurd';
import { homeLoginResolver } from './features/home/resolvers/home-login.resolver';
import { MainComponent } from './core/layout/main/main.component';
import { AuthComponent } from './core/auth/pages/auth/auth.component';
import { TranslationsPageComponent } from './features/translations/pages/translations-page/translations-page.component';
import { ServerTranslationsGuard } from './features/translations/guards/server-reanslation.gaurd';
import { TranslationResolver } from './core/services/translation/translations-resolver.service';
import { Wildcard404Component } from './core/layout/main/components/wildcard-404/wildcard-404.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    resolve: {
      auth: homeLoginResolver,

    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren() {
          return import('./features/home/home.routes').then((m) => m.routes);
        },
        resolve: {
          translations: TranslationResolver,
        },
      },

      {
        path: 'contact-us',
        loadChildren() {
          return import('./features/contact/contact.routes').then(
            (m) => m.routes,
          );
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        // canActivate: [authGaurd],
      },

      {
        path: 'services',
        loadChildren() {
          return import('./features/services/services.routes').then(
            (m) => m.routes,
          );
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        // canActivate: [authGaurd],
      },
      {
        path: 'profile',
        loadChildren() {
          return import('./features/profile/profile.routes').then(
            (m) => m.routes,
          );
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        canActivate: [authGaurd],
      },
      {
        path: 'translate',
        component: TranslationsPageComponent,
        canActivate: [ServerTranslationsGuard]
      },
      {
        path: 'rating',
        loadChildren() {
          return import('./features/rating/rating.routes').then((m) => m.routes);
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        canActivate: [authGaurd],
      },
      {
        path: 'events',
        loadChildren() {
          return import('./features/events/events.routes').then( (m) => m.routes);
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        // runGuardsAndResolvers: 'always',
        // canActivate: [notAuthGaurd],
      },
      {
        path: 'content',
        loadChildren() {
          return import('./features/content/content.routes').then( (m) => m.routes);
        },
      },
      {
        path: 'membership',
        loadChildren: () =>
            import('./features/memberships/membership.routes').then((m) => m.routes),
        resolve: {
            translations: TranslationResolver,
            layout: setLayout(PageLayout.Authorized),
        },
      },
      {
        path: 'jobs',
        loadChildren() {
          return import('./features/jobs/jobs.routes').then( (m) => m.routes);
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
          // runGuardsAndResolvers: 'always',
        // canActivate: [notAuthGaurd],

      },

      {
        path: 'volunteers',
        loadChildren() {
          return import('./features/volunteers/volunteer.routes').then( (m) => m.routes);
        },
        resolve: {
          translations: TranslationResolver,
          layout: setLayout(PageLayout.Authorized),
        },
        // runGuardsAndResolvers: 'always',
        // canActivate: [notAuthGaurd],
      },
      {path: '404', component: Wildcard404Component},

    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [notAuthGaurd],
    resolve: {
      translations: TranslationResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren() {
          return import('./core/auth/auth.routes').then((m) => m.routes);
        },
      },
    ],
  },

  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  }

];
