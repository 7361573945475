export const TranslationModulesKeys: any = {
  "home": 60,
  "services": 62,
  "general": 63,
  "contact-us": 64,
  "profile": 65,
  "events": 66,
  "rating": 67
}

export const TranslateKeys : any = {
  "60" : "unified",
  "62" : "services",
  "63" : "general",
  "64" : "contactUs",
  "65" : "profile",
  "66" : "events",
  "67" : "rating"
}
