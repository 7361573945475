import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NoInternetComponent } from './core/layout/main/components/no-internet/no-internet.component';
import { SidebarComponent } from "./core/layout/main/components/sidebar/sidebar.component";
import { MainModule } from './core/layout/main/main.module';
import { PageLoadingComponent } from './shared/services/page-loading/page-loading.component';
import { PageLoadingService } from './shared/services/page-loading/page-loading.service';
import { PopupsComponent } from "./shared/components/popups/popups.component";
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MainModule,
    CommonModule,
    ToastModule,
    PageLoadingComponent,
    NoInternetComponent,
    SidebarModule,
    SidebarComponent,
    PopupsComponent
],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'unifed-solutions';
  onlineEvent: Observable<Event> | undefined;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  online: boolean = true;
  showLoader: boolean = false;

  constructor(
    private loadingService: PageLoadingService,
    private router: Router,
  ) {
    this.router.events.subscribe((event) => {
      this.trackConnection();
      switch (true) {
        case event instanceof NavigationStart: {
          loadingService.show();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            loadingService.hide();
          }, 500);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  ngOnInit(): void {
  }

  trackConnection(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.showLoader = true;
      this.loadingService.show();
      setTimeout(() => {
        this.showLoader = false;
        this.loadingService.hide();
        this.online = true;
      }, 1000);
    }));


    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.online = false;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
