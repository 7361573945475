<p-sidebar [(visible)]="visibleSidebar" (onHide)="resetAll()">
  @if (formType == "text") {
    <!-- text type input -->
    <div
      class="p-fluid grid formgrid d-flex flex-column justify-content-between"
    >
      <form class="form-1 d-flex flex-column mt-4" [formGroup]="textArEnForm">
        <!-- Arabic Input Field -->
        <div class="field col-12 md:col-6">
          <label for="field1">{{ "sidenav.arLabel" | translate }}</label>
          <div class="p-inputgroup">
            <input
              id="ar"
              type="text"
              pInputText
              formControlName="ar"
              placeholder="Enter value for field 1"
            />
          </div>
          @if (getAr(textArEnForm)?.invalid && getAr(textArEnForm)?.touched) {
            <div class="p-error">
              {{ "sidenav.arLabelRequired" | translate }}
            </div>
          }
        </div>

        <!-- English Input Field -->
        <div class="field col-12 md:col-6">
          <label for="field2">{{ "sidenav.enLabel" | translate }}</label>
          <div class="p-inputgroup">
            <input
              id="en"
              type="text"
              pInputText
              formControlName="en"
              placeholder="Enter value for field 2"
            />
          </div>
          @if (getEn(textArEnForm)?.invalid && getEn(textArEnForm)?.touched) {
            <div class="p-error">
              {{ "sidenav.enLabelRequired" | translate }}
            </div>
          }
        </div>

        <!-- Edit Button -->
      </form>
      <div class="action-btns d-flex justify-content-between gap-4 mt-5">
        <button
          type="submit"
          pButton
          label="{{ 'sidenav.saveBtn' | translate }}"
          icon="pi pi-save"
          icon="{{ loading ? 'pi pi-spin pi-spinner': 'pi pi-save' }}"
          class="p-button-rounded p-button-success p-button-lg"
          [disabled]="loading || textArEnForm?.invalid || textArEnForm?.pristine"
          (click)="onSubmit()"
        ></button>
        <button
          type="button"
          pButton
          label="{{ 'sidenav.closeBtn' | translate }}"
          icon="pi pi-times"
          class="p-button-rounded close p-button-danger p-button-lg ml-3"
          (click)="sidebarService.closeSidebar()"
        ></button>
      </div>
    </div>
  } @else if (formType == "text-editor") {
    <!-- Text type input with PrimeNG text editors -->
    <div
      class="p-fluid grid formgrid d-flex flex-column justify-content-between"
    >
      <form class="form-1 mt-4" [formGroup]="textArEnForm">
        <!-- Arabic Input Field (Ar Text Editor) -->
        <div class="field col-12">
          <label for="ar">{{ "sidenav.arLabel" | translate }}</label>
          <div class="p-inputgroup">
            <p-editor
              id="ar"
              formControlName="ar"
              placeholder="Enter value for Ar"
              [style]="{ height: '200px' }"
            ></p-editor>
          </div>
          <!-- Error Message -->
          @if (getAr(textArEnForm)?.invalid && getAr(textArEnForm)?.touched) {
            <div class="p-error">
              {{ "sidenav.arLabelRequired" | translate }}
            </div>
          }
        </div>

        <!-- English Input Field (En Text Editor) -->
        <div class="field col-12">
          <label for="en">{{ "sidenav.enLabel" | translate }}</label>
          <div class="p-inputgroup">
            <p-editor
              id="en"
              formControlName="en"
              placeholder="Enter value for En"
              [style]="{ height: '200px' }"
            ></p-editor>
          </div>
          <!-- Error Message -->
          @if (getEn(textArEnForm)?.invalid && getEn(textArEnForm)?.touched) {
          <div
            class="p-error"
          >
            {{ "sidenav.enLabelRequired" | translate }}
          </div>
        }
        </div>
      </form>
      <!-- Buttons Section -->
      <div class="action-btns d-flex justify-content-between gap-4 mt-5">
        <button
          type="submit"
          pButton
          label="{{ 'sidenav.saveBtn' | translate }}"
          class="p-button-rounded p-button-success p-button-lg"
          icon="{{ loading ? 'pi pi-spin pi-spinner': 'pi pi-save' }}"
          [disabled]="loading ||textArEnForm?.invalid || textArEnForm?.pristine"
          (click)="onSubmit()"
        ></button>
        <button
          type="button"
          pButton
          label="{{ 'sidenav.closeBtn' | translate }}"
          icon="pi pi-times"
          class="p-button-rounded close p-button-danger p-button-lg ml-3"
          (click)="sidebarService.closeSidebar()"
        ></button>
      </div>
    </div>
  }
   @else if (formType == "img") {
    <!-- Image form-->
    <div
      class="p-fluid grid formgrid d-flex flex-column justify-content-between"
    >
      <form
        class="form-1 mt-4"
        [formGroup]="imgUploadForm"
        (ngSubmit)="onSubmitImage()"
      >
        <!-- Image Upload Field -->
        <div class="field col-12 md:col-6">
          <div class="field col-12 md:col-6">
            <label for="field1">{{ "sidenav.arLabel" | translate }}</label>
            <div class="p-inputgroup">
              <input
                id="ar"
                type="text"
                pInputText
                formControlName="ar"
                placeholder="Enter value for field 1"
              />
            </div>
            @if (
              getAr(imgUploadForm)?.invalid && getAr(imgUploadForm)?.touched
            ) {
              <div class="p-error">
                {{ "sidenav.arLabelRequired" | translate }}
              </div>
            }
          </div>
          <div class="field col-12 md:col-6">
            <label for="field1">{{ "sidenav.enLabel" | translate }}</label>
            <div class="p-inputgroup">
              <input
                id="en"
                type="text"
                pInputText
                formControlName="en"
                placeholder="Enter value for field 1"

              />
            </div>
            @if (
              getEn(imgUploadForm)?.invalid && getEn(imgUploadForm)?.touched
            ) {
              <div class="p-error">
                {{ "sidenav.enLabelRequired" | translate }}
              </div>
            }
          </div>
          <div class="field col-12 md:col-6">
            <label for="field1">{{ "sidenav.imgLabel" | translate }}</label>
            <div class="p-inputgroup">
              <input
                id="imageUpload"
                type="file"
                pInputText
                formControlName="image"
                accept="image/*"
                #fileInput
                (change)="onFileSelected($event)"
              />
            </div>
          </div>
          <!-- @if (image?.invalid && image?.touched) {
            <div class="p-error">Please upload a valid image.</div>
          } -->
        </div>
        <!-- Submit Button -->
        <div class="action-btns d-flex justify-content-between gap-4 text-center mt-5">
          <button
            type="submit"
            pButton
            label="{{ 'sidenav.saveBtn' | translate }}"
            class="p-button-rounded p-button-info p-button-lg"

            icon="{{ loading ? 'pi pi-spin pi-spinner': 'pi pi-save' }}"
            [disabled]="loading ||imgUploadForm?.invalid || imgUploadForm?.pristine"
          ></button>
          <button
            type="button"
            pButton
            label="{{ 'sidenav.closeBtn' | translate }}"
            icon="pi pi-times"
            class="p-button-rounded close p-button-danger p-button-lg ml-3"
            (click)="sidebarService.closeSidebar()"
          ></button>
        </div>
      </form>
    </div>
  }
   @else if (formType == "list-item") {
    <!-- list-item form-->
    <div class="p-fluid grid formgrid d-flex flex-column justify-content-between">
      <form
        class="form-1 mt-4"
        [formGroup]="listItemForm"
        (ngSubmit)="onSubmitByParentId()"
      >
        <!-- List-Item ID Field -->
        <div class="field col-12 md:col-6 d-none">
          <label for="parentId">{{ 'sidenav.parentId' | translate }}</label>
          <div class="p-inputgroup">
            <input
              id="parentId"
              type="text"
              pInputText
              formControlName="parentId"
              [placeholder]="'sidenav.parentId' | translate"
              [attr.disabled]="true"


            />
          </div>
        </div>

        <!-- Value Group -->
        <div formGroupName="value">
          <!-- Value ID Field -->
          <div class="field col-12 md:col-6 d-none">
            <label for="itemIdLabel">{{ 'sidenav.itemIdLabel' | translate }}</label>
            <div class="p-inputgroup">
              <input
                id="itemIdLabel"
                type="text"
                pInputText
                formControlName="id"
                [placeholder]="'sidenav.itemIdLabel' | translate"
                disabled

              />
            </div>
          </div>

          <!-- Date Field -->
          <div class="field col-12 md:col-6">
            <label for="date">{{ 'sidenav.dateLabel' | translate }}</label>
            <div class="p-inputgroup">
              <input
                id="date"
                type="date"
                pInputText
                formControlName="date"
              />
            </div>
          </div>

          <!-- Description Fields -->
          <div formGroupName="description">
            <div class="field col-12 md:col-6">
              <label for="descriptionAr">{{ 'sidenav.arLabel' | translate }}</label>
              <div class="p-inputgroup">
                <input
                  id="descriptionAr"
                  type="text"
                  pInputText
                  formControlName="ar"
                  [placeholder]="'sidenav.arLabel' | translate"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="descriptionEn">{{ 'sidenav.enLabel' | translate }}</label>
              <div class="p-inputgroup">
                <input
                  id="descriptionEn"
                  type="text"
                  pInputText
                  formControlName="en"
                  [placeholder]="'sidenav.enLabel' | translate"

                />
              </div>
            </div>
          </div>

          <!-- Author Fields -->
          <div formGroupName="author">
            <div class="field col-12 md:col-6">
              <label for="authorAr">{{ 'sidenav.authorArLabel' | translate }}</label>
              <div class="p-inputgroup">
                <input
                  id="authorAr"
                  type="text"
                  pInputText
                  formControlName="ar"
                  [placeholder]="'sidenav.authorArLabel' | translate"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="authorEn">{{ 'sidenav.authorEnLabel' | translate }}</label>
              <div class="p-inputgroup">
                <input
                  id="authorEn"
                  type="text"
                  pInputText
                  formControlName="en"
                  [placeholder]="'sidenav.authorEnLabel' | translate"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="action-btns d-flex justify-content-between gap-4 text-center mt-5">
          <button
            type="submit"
            pButton
            label="{{ 'sidenav.saveBtn' | translate }}"
            icon="{{ loading ? 'pi pi-spin pi-spinner': 'pi pi-save' }}"
            [disabled]="loading ||listItemForm?.invalid || listItemForm?.pristine"
            class="p-button-rounded p-button-info p-button-lg"
          ></button>
          <button
            type="button"
            pButton
            label="{{ 'sidenav.closeBtn' | translate }}"
            icon="pi pi-times"
            class="p-button-rounded close p-button-danger p-button-lg ml-3"
            (click)="sidebarService.closeSidebar()"
          ></button>
        </div>
      </form>
    </div>

  }
</p-sidebar>
