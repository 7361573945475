import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-wildcard-404',
  standalone: true,
  imports: [TranslateModule, RouterLink],
  templateUrl: './wildcard-404.component.html',
  styleUrl: './wildcard-404.component.scss'
})
export class Wildcard404Component {

}
