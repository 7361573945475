import { Component, inject } from '@angular/core';
import { ServerTranslationsService } from '../../services/server-translations-service';
import { LangConfig } from '@app/core/configs/lang.config';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-translations-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './translations-page.component.html',
  styleUrl: './translations-page.component.scss',
  providers: [ServerTranslationsService],
})
export class TranslationsPageComponent {
  private readonly serverTranslateService = inject(ServerTranslationsService);
  isDisable: boolean = false;
  actions = this.serverTranslateService.actions;


  // publics
  onStart() : void {
    this.isDisable = true;
    this.serverTranslateService.startFlow().subscribe({
      next: (res) => {
        this.isDisable = false;
      },
      error: (err) => {
        this.isDisable = false;
      },
    });
  }
}
