import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SidebarService } from '@app/features/contact/services/sidebar.service';
import { ContentEditorService } from '@app/shared/services/content-editor.service';
import { PageLoadingService } from '@app/shared/services/page-loading/page-loading.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    EditorModule,
    TranslateModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  public sidebarService = inject(SidebarService);
  contentEditorService = inject(ContentEditorService);
  visibleSidebar: boolean = false;
  data: any;
  fb = inject(FormBuilder);
  textArEnForm!: FormGroup;
  imgUploadForm!: FormGroup;
  listItemForm!: FormGroup;
  uploadStatus: string = '';
  formType = '';
  selectedFile: File | null = null;
  loaderService = inject(PageLoadingService);
  loading = false;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  resetFileInput() {
    this.fileInput.nativeElement.value = null;
  }
  ngOnInit(): void {
    // this.resetFileInput();
    this.sidebarService.formType.subscribe((res: any) => {
      this.formType = res;
    });

    // text form
    this.textArEnForm = this.fb.group({
      ar: ['', Validators.required], // text ar
      en: ['', Validators.required], // text en
      id: [''], // item id
      parentId: [''], // item id
      valueType: [''], // item id
    });

    // image form
    this.imgUploadForm = this.fb.group({
      image: ['', Validators.required], // image url
      ar: ['', Validators.required], // alternate text ar
      en: ['', Validators.required], // alternate text en
      id: [''], // item id
      parentId: [''], // parent id
      valueType: [''], // item id
    });

    // list item
    this.listItemForm = this.fb.group({
      parentId: [null],
      valueType: [null],
      value: this.fb.group({
        id: [null],
        date: [null],
        description: this.fb.group({
          ar: [null],
          en: [null],
        }),
        author: this.fb.group({
          ar: [null],
          en: [null],
        }),
      }),
    });

    this.sidebarService.visibility$.subscribe((res) => {
      this.visibleSidebar = res;
    });

    this.sidebarService.getData().subscribe((data: any) => {
      this.data = data;
      if (data.formType === 'list-item') {
        this.listItemForm.patchValue(this.data);
        // patch date alone
        const formattedDate = data?.value?.date?.split('T')[0];
        this.listItemForm.get('value.date')?.patchValue(formattedDate);

        // disable id
        this.listItemForm.get('value.id')?.disable();
        // disable parentId
        this.listItemForm.get('parentId')?.disable();
      } else if (data.formType === 'text') {
        this.textArEnForm.patchValue(this.data);
      } else if (data.formType === 'text-editor') {
        this.textArEnForm.patchValue(this.data);
      } else {
        this.imgUploadForm.patchValue(this.data);
      }
    });
  }

  // Getter methods for easy access to form controls
  getAr(form: FormGroup) {
    return form.get('ar');
  }

  getEn(form: FormGroup) {
    return form.get('en');
  }

  onSubmit() {
    this.loading = true;

    if (this.textArEnForm.valid) {
      // Handle the form submission
      if (this.sidebarService.addOrEdit.value === 'add') {
        this.sidebarService.addData({
          parentId: this.textArEnForm.get('parentId')?.value, // parent id
          valueType: this.textArEnForm.get('valueType')?.value, // parent id
          value: {
            ar: this.textArEnForm.get('ar')?.value,
            en: this.textArEnForm.get('en')?.value,
          },
        });
      } else {
        this.sidebarService.editData({
          id: this.textArEnForm.get('id')?.value,
          value: {
            ar: this.textArEnForm.get('ar')?.value,
            en: this.textArEnForm.get('en')?.value,
          },
        });
      }
    } else {
      // Form is invalid, display error or handle appropriately
      this.textArEnForm.markAllAsTouched(); // Mark fields as touched to show validation errors
    }
  }

  onSubmitByParentId() {
    this.loading = true;

    if (this.listItemForm.valid) {
      // Handle the form submission

      if (this.sidebarService.addOrEdit.value === 'add') {
        this.sidebarService.addData({
          parentId: this.listItemForm.get('parentId')?.value,
          valueType: this.listItemForm.get('valueType')?.value,
          value: {
            id: 0,
            date: this.listItemForm.get('value.date')?.value,
            description: {
              ar: this.listItemForm.get('value.description.ar')?.value,
              en: this.listItemForm.get('value.description.en')?.value,
            },
            author: {
              ar: this.listItemForm.get('value.author.ar')?.value,
              en: this.listItemForm.get('value.author.en')?.value,
            },
          },
        });
      } else {
        this.sidebarService.editData({
          id: this.listItemForm.get('parentId')?.value,
          value: {
            id: this.listItemForm.get('value.id')?.value,
            date: this.listItemForm.get('value.date')?.value,
            description: {
              ar: this.listItemForm.get('value.description.ar')?.value,
              en: this.listItemForm.get('value.description.en')?.value,
            },
            author: {
              ar: this.listItemForm.get('value.author.ar')?.value,
              en: this.listItemForm.get('value.author.en')?.value,
            },
          },
        });
      }
    } else {
      // Form is invalid, display error or handle appropriately
      this.listItemForm.markAllAsTouched(); // Mark fields as touched to show validation errors
    }
  }

  onSubmitImage(): void {
    this.loading = true;
    if (this.imgUploadForm.valid && this.selectedFile) {
      // Ensure file is selected
      // Use the selected file from the variable
      this.contentEditorService.uploadImage(this.selectedFile).subscribe({
        next: (res) => {
          console.log(res);
          this.uploadStatus = 'success';
          // Call the editItem service with updated form values
          if (this.sidebarService.addOrEdit.value === 'add') {
            this.contentEditorService
              .addItem({
                parentId: this.imgUploadForm.get('parentId')?.value,
                valueType: this.imgUploadForm.get('valueType')?.value,
                value: {
                  title: {
                    ar: this.imgUploadForm.get('ar')?.value,
                    en: this.imgUploadForm.get('en')?.value,
                  },
                  url: res.fileUrl,
                },
              })
              .subscribe(() => {
                console.log('image upload done');
                this.sidebarService.selectedFormData.next(false);
                this.selectedFile = null;
                this.resetAll();
                this.sidebarService.closeSidebar();
              });
          } else {
            this.contentEditorService
              .editItem({
                id: this.imgUploadForm.get('id')?.value,
                value: {
                  title: {
                    ar: this.imgUploadForm.get('ar')?.value,
                    en: this.imgUploadForm.get('en')?.value,
                  },
                  url: res.fileUrl,
                },
              })
              .subscribe(() => {
                console.log('image upload done');
                this.sidebarService.selectedFormData.next(false);
                this.selectedFile = null;
                this.resetAll();
                this.sidebarService.closeSidebar();
              });
          }
        },
        error: (err) => {
          console.log(err);
          this.uploadStatus = 'error';
          this.loading =true;
        },

        complete: () => {
          this.loading =true;

        },
      });
    }
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]; // Store the file in the variable
    }
  }

  resetAll() {
    this.listItemForm.reset();
    this.textArEnForm.reset();
    this.imgUploadForm.reset();
    this.listItemForm.markAsPristine();
    this.textArEnForm.markAsPristine();
    this.imgUploadForm.markAsPristine();
    this.selectedFile = null;
    this.resetFileInput();
    this.loading = false;

  }
  ngOnDestroy() {
    this.sidebarService.setData(null);
    this.resetAll();
  }

}
