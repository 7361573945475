export const Navs = [
  { name: 'homePage', link: '/home', hasChildren: false },
  { name: 'services', link: '/home', hasChildren: false, toBlock: '#services' },
  {
    name: 'aboutUs',
    link: 'content/about-us',
    hasChildren: false,
  },
  {
    name: 'aboutShikha',
    link: 'content/about-shikha',
    hasChildren: true,
    subMenus: [
      { name: 'breifeAboutShikha', link: '/content/about-shikha' },
      { name: 'shikhaSpeech', link: '/content/shikha-speech' },
      { name: 'shikhAwards', link: '/content/shikha-awards' },
      { name: 'shikhaSayings', link: '/content/shikha-sayings' },
    ],
  },
  {
    name: "shikhaInstitutions",
    link: 'content/shikha-organizations',
    hasChildren: true,
    subMenus: [
      { name: 'developingAndAbilities', link: '/content/shikha-organizations', param : 0 },
      { name: 'elevatingSocietyAndFamilies', link: '/content/shikha-organizations', param : 1 },
      { name: 'empoweringWomen', link: '/content/shikha-organizations', param : 2 },
      { name: 'charityAndBenevolence', link: '/content/shikha-organizations' , param : 3},
    ],
  },
  // {
  //   name: 'ourCorps',
  //   link: '',
  //   hasChildren: false,
  //   subMenus: [
  //     { name: 'Action', link: '' },
  //     { name: 'Another action', link: '' },
  //     { name: 'Something else here', link: '' },
  //   ],
  // },
  // {
  //   name: 'aboutOwner',
  //   link: '',
  //   hasChildren: false,
  //   subMenus: [
  //     { name: 'Action', link: '' },
  //     { name: 'Another action', link: '' },
  //     { name: 'Something else here', link: '' },
  //   ],
  // },
  { name: 'contactUs', link: '/contact-us', hasChildren: false },
];

export const FooterNavs = [
  { name: 'homePage', link: '/', hasChildren: false },
  { name: 'services', link: '/', hasChildren: false },
  // {
  //   name: 'aboutOwner',
  //   link: '/',
  //   hasChildren: false,
  // },
  // {
  //   name: 'ourCorps',
  //   link: '/',
  //   hasChildren: false,
  // },

  { name: 'contactUs', link: '/contact-us', hasChildren: false },
];
