import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";

@Injectable({
  providedIn: 'root'
})

export class LocalStorage extends CacheService {

  public set(key: string, value: string): void {
    if (key && value)
      localStorage.setItem(key, value);
  }

  public get(key: string): any {
    if (key)
      return localStorage.getItem(key);
    return null;
  }

  public remove<T>(key: string): void {
    localStorage.removeItem(key);
  }

}
