import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export abstract class CacheService {

    protected abstract set(key: string, value: string): void;
    protected abstract get(key: string): string;
    protected abstract remove<T>(key: string): void;

}