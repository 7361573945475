import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './main.component';
import { NavComponent } from './components/nav/nav.component';
import { UserComponent } from './components/user/user.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthService } from '@app/core/auth/services/auth.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    HeaderComponent,
    MainComponent,
    UserComponent,
    FooterComponent,
  ],
  exports: [
    HeaderComponent,
    MainComponent,
    UserComponent,
    FooterComponent,
    NavComponent,
  ],
  imports: [CommonModule, NavComponent, RouterModule, TooltipModule,TranslateModule],
  providers: [AuthService],
})
export class MainModule {}
