import { Component } from '@angular/core';
import { PageLoadingService } from './page-loading.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-page-loading',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './page-loading.component.html',
  styleUrl: './page-loading.component.scss'
})
export class PageLoadingComponent {
  loading$ = this.loadingService.loading$;

  constructor(private loadingService: PageLoadingService) { }
}
