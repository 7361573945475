import { AlertService } from './../../../core/auth/services/alert-service.service';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { environment } from '@environment/enviroment';

export const ServerTranslationsGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router: Router = inject(Router);
  const alertService: AlertService = inject(AlertService);

  if (environment.production) {
    alertService.alertError(
      'This Page Not Accessible On Production',
      'This Page Not Accessible On Production',
    );
    router.navigate(['/']);
    return false;
  } else {
    return true;
  }
};
