import { Component } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@Component({
  selector: 'app-popups',
  standalone: true,
  imports: [ToastModule, DialogModule, ConfirmDialogModule],
  templateUrl: './popups.component.html',
  styleUrl: './popups.component.scss',
})
export class PopupsComponent {
  constructor(
  ) {}

}
