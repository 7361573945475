import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-no-internet',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './no-internet.component.html',
  styleUrl: './no-internet.component.scss',
})
export class NoInternetComponent {
  @Input() connectionStatusMessage!: string;
  @Input() connectionStatusTitle!: string;
}
