import ar_SA from '@angular/common/locales/ar-SA';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  Injector,
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { apiInterceptor } from './core/auth/services/api.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  createTranslateLoader,
  MyMissingTranslationHandler,
} from './core/services/translation/i18n-config';
import { CustomLanguageService } from './core/services/language-service';
import { ApplicationInitializerFactory } from './core/services/translations.config';

registerLocaleData(ar_SA);

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    // provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimations(),
    ConfirmationService,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [CustomLanguageService, HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MyMissingTranslationHandler,
        },
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      multi: true,
      deps: [Injector],
    },
    provideHttpClient(withInterceptors([apiInterceptor])),

    MessageService,
  ],
};
