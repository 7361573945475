import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  map,
  mergeMap,
  Observable,
  of,
  tap
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeys } from '@app/core/configs/storage.config';
import { CustomLanguageService } from '../language-service';
import { TranslationModulesKeys } from './translation-modules-enum';


@Injectable({ providedIn: 'root' })
export class
  TranslationResolver implements Resolve<boolean> {
  languageService = inject(CustomLanguageService)
  constructor(private translateService: TranslateService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if(this.languageService.translationMode.value == "external"){
      let moduleNumber: number
      const lang = localStorage.getItem(StorageKeys.language)
        || this.translateService.getDefaultLang();
        !route.url[0]?.path ? moduleNumber = TranslationModulesKeys["home"] : moduleNumber = TranslationModulesKeys[route.url[0].path]
      if (!this.languageService.translations.find(x => x.id === moduleNumber)) {
        this.translateService.use(lang)
        return this.languageService.getUnAuthTranslation(moduleNumber)
          .pipe(mergeMap(module =>
            this.languageService.shared.pipe(map((shared) => {
              let ar = { ...module.ar, ...(shared as any).ar }
              let en = { ...module.en, ...(shared as any).en }
              let lang = { ar: ar, en: en }
              this.languageService.languageData.next(lang);
              this.languageService.translations.push({ id: moduleNumber, data: lang })
              this.translateService.setTranslation("ar", ar)
              this.translateService.setTranslation("en", en)
              return lang as any
            }))))
          .pipe(map(l => {
            return l
          }))
      }
      else {
        this.translateService.use(lang)
        let data = this.languageService.translations.find(x => x.id === moduleNumber).data
        let shared = this.languageService.translations.find(x => x.id === 63).data
        let ar = { ...data.ar, ...shared.ar }
        let en = { ...data.en, ...shared.en }
        this.languageService.languageData.next(data)
        this.translateService.setTranslation("ar", ar)
        this.translateService.setTranslation("en", en)
        return data[lang]


      }

    }else {
      // const lang = localStorage.getItem(StorageKeys.language)
      // || this.translateService.getDefaultLang();
      // return this.translateService.getTranslation(lang)
      return of(true)
    }


  }
}
