@if (online) {
  <p-toast />
  <app-page-loading></app-page-loading>
  <router-outlet></router-outlet>
  <app-sidebar></app-sidebar>
  <app-popups></app-popups>
} @else {
  @if (showLoader) {
    <app-page-loading></app-page-loading>
  } @else {
    <app-no-internet
      [connectionStatusMessage]="'general.messages.connectionStatusMessage'"
      [connectionStatusTitle]="'general.messages.connectionStatus'"
    >
    </app-no-internet>
  }
}
